/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

/* 
@media (max-width: 1545px) {
  html {
    zoom: 0.8;
  }
} */

p.success {
  color: green;
}

p.danger {
  color: red;
}

/* -------------------------------------------------------------NavBar--------------------------------- */
.container {
  max-width: 85% !important;
  margin-left: 3% !important;
  margin-right: 15% !important;
  /*Set your own width %; */
}

nav.navbar {
  padding: 1% 0;
  position: fixed;
  width: 100%;
  top: 0;
  transition: 0.5s ease-in-out;
  z-index: 9999;
}

.nav-link {
  color: #fff !important;
  opacity: 0.75;
  font-size: 125% !important;
  margin-right: 10%;
  padding-top: 10px !important;
  padding-top: 0;
  padding-bottom: 0;
  transition: 0.3s ease-in-out;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  transition: 0.3s ease-in-out;
  opacity: 1;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.75%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");

}

.navbar-light .navbar-toggler {
  border: 1px solid white;
}

.navbar-light .navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  box-shadow: 0 0 5px whitesmoke;
}

.navbar-toggler-border-color {
  color: #fff;
}

nav.navbar.scrolled {
  background-color: #121212;
  opacity: 1;
}

.image-resize {
  width: 50px;
  height: 50px;
}

.navbar-brand {
  color: whitesmoke !important;
  font-size: 190% !important;
  padding: 0;
  margin-top: -10px;
}

.social-icon {
  display: flex;
  margin-top: -8px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 10%;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

@media (max-width: 1200px) {
  .navbar-toggler {
    order: -1;
    float: right;
  }

  .navbar-collapse {
    background-color: #1d1919;
    padding: 10px;
  }

  .container {
    margin-right: 35% !important;
  }
}



/* -----------------------------------------------------------Home---------------------------------------- */
.home {
  margin-top: 0;
  /* padding: 8% 0 20% 0; */
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  background-position: top center;
  margin-bottom: -50px;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-container {
  display: flex;
  padding-top: 2rem;
  padding-left: 2rem;
  height: 100vh;
  align-items: center;
}

.home-left {
  width: 60%;
  max-height: 50rem;
  padding-left: 5rem;
  padding-right: 5rem;
  /* padding-top: 10rem; */
  justify-content: center;
  align-items: center;
}

.home-right {
  width: 40%;
  max-height: 60rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5rem;
}

.home h1 {
  display: flex;
  font-size: 4rem;
}

.h1-container {
  display: flex;
  justify-content: left;
  align-items: left;
}

.type-writer {
  background-image: linear-gradient(to right, #b24592, #f15f79);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home body {
  font-size: 1.5rem;
  text-align: left;
  opacity: 0.9 !important;
  background: none !important;

}

.downarrow {
  opacity: 0.5;
  margin-top: 50px;
  transition: 0.3s ease-in-out;
}

.downarrow:hover {
  opacity: 1.5;
  transition: 0.3s ease-in-out;
}

.face-resize {
  max-width: 30rem;
  max-height: 30rem;
  border-radius: 20%;
}

.face-container {
  flex-grow: 1;
}

.dailyfact-container {
  padding-top: 3rem;
  flex-grow: 15;
}

.fact-container {
  display: flex;
  max-width: 30rem;
}

.fact {
  font-size: 110%;
  text-align: left !important;
  color: white;
  opacity: 0.8;
  max-width: 22rem;
  padding-left: 1rem;
}

.fact-prompt {
  font-size: 125%;
  min-width: 9rem;
}

.year {
  background-image: linear-gradient(to right, #b24592, #f15f79);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.filler-space {
  margin: 0;
  padding: 0;
  opacity: 0.5;
}

.fact-button {
  margin-top: 1rem;
}

.home-buttons {
  display: flex;
  gap: 0.15rem;
  justify-content: center;
}

/* -----------------------------------------------------------Projects---------------------------------------- */
#projects {
  background: black;
  padding: 5rem 0 5% 0;
  margin-top: 0;
}

.project-repo {
  color: white;
  text-decoration: none;
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}

.project-repo:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

#projects h1 {
  margin-top: 0;
  text-align: center;
}

.projects-container {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5rem;
  padding-right: 5rem;
}

.cardandarrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  padding-left: 1rem;
  padding-right: 1rem;
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}

.arrow:hover {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.project-component {
  border-radius: 1rem;
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
}

.card-text {
  max-width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
}

.card-text h1 {
  text-align: left;
  padding-bottom: 1rem;
}

.card-button {
  max-width: 7rem;
  padding-top: 1rem;
}

.project-button {
  color: white;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition: 0.3s ease-in-out;
}

.project-button:hover {
  box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
}

.gif {
  display: flex;
  justify-content: center;
  align-items: center !important;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 50%;
  padding-left: 6rem;
}

.blog-gif {
  width: 100%;
  object-fit: contain;
  border-radius: 1rem;
}

.music-gif {
  width: 115%;
  object-fit: contain;
  border-radius: 1rem;
}

.python-gif {
  border-radius: 1rem;
  object-fit: contain;
}

.salt-pic {
  border-radius: 1rem;
}

.dotbar-container {
  display: flex;
  max-height: 5rem;
  padding-top: 3rem;
}

.dot {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.dotOn {
  max-height: 0.75rem;
  max-width: 0.75rem;
  transition: 0.3s ease-in-out;
}

.dotOff {
  max-height: 0.75rem;
  max-width: 0.75rem;
  transition: 0.3s ease-in-out;
  opacity: 0.5;
}

/* -----------------------------------------------------------Contact---------------------------------------- */
#contact {
  background: linear-gradient(90.21deg, rgba(74, 47, 189, 0.5), rgba(26, 125, 238, 0.5) 100.58%);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 0 5% 0;
}

#contact h1 {
  color: white;
  padding-bottom: 3rem;
}

.contact-container {
  display: flex;
  padding-top: 5rem;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.myemail-container {
  padding-bottom: 1rem;
}

.myemail {
  font-size: 1.5rem;
  opacity: 0.5;
}

.email-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.email-field {
  font-size: 1.5rem;
  width: 50rem;
  text-align: center;
  border-radius: 0.5rem;
}

.name-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.name-field {
  font-size: 1.5rem;
  width: 50rem;
  text-align: center;
  border-radius: .5rem;
}

.message-container {
  padding-top: 1rem;
  display: flex;
}

.message-field {
  font-size: 1.5rem;
  width: 50rem;
  height: 15rem;
  border-radius: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.email-field::placeholder,
.name-field::placeholder,
.message-field::placeholder {
  color: rgb(145, 147, 181);
}

.submit-container {
  padding-top: 1rem;
}

.submit {
  background: rgba(74, 47, 189, 0.5);
  color: white;
  opacity: 0.68;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  border-color: none;
  padding-bottom: 0.5rem;
  transition: 0.2s ease-in-out;
  border: none;
}

.submit:hover {
  transition: 0.2s ease-in-out;
  opacity: 1;
}


.envelope-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding-right: 5%;
}

.envelope {
  display: flex;
  height: 100%;
  width: 50%;
}


/* -----------------------------------------------------------about---------------------------------------- */
#about {
  background: black;
}

.colophon {
  height: 50rem;
  border-radius: 50%;
  background: linear-gradient(90.21deg, rgba(74, 47, 189, 0.5), rgba(26, 125, 238, 0.5) 100.58%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle-text {
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.2;
  width: 60%;
  height: 60%;
  padding-top: 2rem;
}

.about-card {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background: linear-gradient(90.21deg, rgba(74, 47, 189, 0.5), rgba(26, 125, 238, 0.5) 100.58%);
  border-radius: 1rem;
  justify-content: space-around;
  align-items: center;
  height: 20rem;
}

.react-resize {
  height: 10rem;
  width: 13rem;
}

.js-resize {
  height: 10rem;
  width: 15rem;
}

.python-resize {
  height: 10rem;
  width: 10rem;
}

.cpp-resize {
  height: 10rem;
  width: 10rem;
}

.reliablyMe-resize {
  height: 8rem;
  width: 8rem;
}

.BoC-resize {
  height: 8rem;
  width: 8rem;
  border-radius: 0.4rem;
}

.UWFE-resize {
  height: 8rem;
  width: 8rem;
  border-radius: 0.4rem;
}

.ford-resize {
  height: 8rem;
  width: 8rem;
  border-radius: 0.4rem;
}

.experience-card {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background: linear-gradient(90.21deg, rgba(74, 47, 189, 0.5), rgba(26, 125, 238, 0.5) 100.58%);
  border-radius: 1rem;

}

.experience-title-container {
  padding-left: 2rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* -----------------------------------------------------------footer--------------------------------------- */
#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

#footer>div:first-child {
  margin-right: auto;
}

.social-icon-footer {
  display: flex;
}

.social-icon-footer a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 10px;
  /* Adjust this value to increase or decrease the spacing between icons */
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon-footer a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon-footer a:hover::before {
  transform: scale(1);
}